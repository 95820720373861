import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import * as styles from "./Gallery.module.css";

const Gallery = () => {
  return (
    <div className={styles.container}>
      <div className={styles.GalleryText}>
        <h2>Galleri</h2>
        <p>
          Se mer på{" "}
          <a
            href="https://instagram.com/cheveu_sthlm?utm_medium=copy_link"
            target="_blank"
            rel="noreferrer"
            className={styles.InstaLink}
          >
            Instagram{" "}
            <FontAwesomeIcon icon={faInstagram} className={styles.InstaIcon} />
          </a>
        </p>
      </div>
      <div className={styles.Gallery}>
        <div
          className={styles.ImageContainer}
          data-sal="slide-right"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture4.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture2.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-left"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture3.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-right"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture1.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture5.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-left"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture6.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-right"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture7.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture16_copy.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-left"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture15_copy.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-right"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture11.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture12.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-left"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture13.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-right"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture17.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture18.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
        <div
          className={styles.ImageContainer}
          data-sal="slide-left"
          data-sal-duration="1000"
          data-sal-delay="3000"
          data-sal-easing="ease"
        >
          <StaticImage
            src={"../../images/picture19.jpg"}
            className={styles.Image}
            objectFit={"cover"}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
