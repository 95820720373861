import React from 'react';
import Gallery from '../components/gallery/Gallery';
import Layout from '../components/global/Layout';

const galleri = () => {
    return (
        <Layout>
            <Gallery />
        </Layout>
    );
};

export default galleri;
